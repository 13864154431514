import React, { Component } from 'react';
import Tab from '../../common/navigation/Tab';
import { BulkRunSubNav, BulkTableHeaders } from '../../common/Constants'
import PagingTable from '../../common/tables/PagingTable';
import { GET, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import { formatDateTime, setFilterData, getFilterData, removeFilterData } from '../../../Utilities';
import { NoPendingRuns, ViewDetails, Promote, RunFilter, ConfirmQueueJumpModal, SuccessQueueJumpModal, ErrorQueueJumpModal } from '../Snippets';

class BulkPack extends Component {

    state = {
        loading: true,
        pageIndex: 1,
        pageCount: 1,
        pendingRuns: [],
        couriers: [],
        filteredResults: false,
        filter: {
            search: "",
            courier: "",
        },
        promotedRun: {},
        showConfirmQueueJumpModal: false,
        showSuccessQueueJumpModal: false,
        showErrorQueueJumpModal: false
    };

    async componentDidMount() {
        Promise.all([
            await this.setStorageFilter(),
            await this.getPendingRuns(),
            await this.getCouriers()
        ]);
    }

    setStorageFilter = () => {
        const filterData = getFilterData("bulkPack");
        if (filterData) {
            this.setState({
                pageIndex: filterData.pageIndex,
                filter: {
                    search: filterData.search,
                    courier: filterData.courier
                }
            });
        }
        removeFilterData("bulkPack");
    }

    getPendingRuns = () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RUNS.GET.BULK_PACK);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const pendingRuns = results ? results.data.orders : [];
                const pageCount = results ? results.data.pageCount : 0;
                this.setState({ pendingRuns: pendingRuns, pageCount: pageCount, loading: false });
            });
    }

    getCouriers = () => {
        return GET(Endpoints.WAREHOUSE.GET.COURIERS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const couriers = results ? results.data : [];
                this.setState({ couriers: couriers });
            });
    }

    promoteRun = () => {
        const { id } = this.state.promotedRun;
        this.setState({ loading: true });

        return PUT(Endpoints.RUNS.PUT.BULK_PACK_BUMP.replace("{id}", id))
            .catch(e => {
                this.setState({ showErrorQueueJumpModal: true, showConfirmQueueJumpModal: false, loading: false });
                console.log(e);
            })
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (result) {
                    this.setState({ showSuccessQueueJumpModal: true, showConfirmQueueJumpModal: false, pageIndex: 1 });
                    this.getPendingRuns();
                } else this.setState({ showErrorQueueJumpModal: true, showConfirmQueueJumpModal: false, loading: false });
            });
    }

    onDetailsClick = (id) => {
        const { pageIndex, filter } = this.state;
        setFilterData("bulkPack", { pageIndex: pageIndex, search: filter.search, courier: filter.courier });

        this.props.history.push(`/runs/bulk/pack/${id}`);
    }

    onPromoteClick = (id, runName, orderNumber, customerName) => {
        this.setState({
            promotedRun: {
                id: id,
                runName: runName,
                orderNumber: orderNumber,
                customerName: customerName
            },
            showConfirmQueueJumpModal: true
        });
    }

    onShowHideConfirmModal = () => this.setState(prevState => ({ showConfirmQueueJumpModal: !prevState.showConfirmQueueJumpModal }));
    onShowHideSuccessModal = () => this.setState(prevState => ({ showSuccessQueueJumpModal: !prevState.showSuccessQueueJumpModal }));
    onShowHideErrorModal = () => this.setState(prevState => ({ showErrorQueueJumpModal: !prevState.showErrorQueueJumpModal }));

    getPendingRunRows = () => {
        const { pendingRuns } = this.state;
        const pendingRunRows = [];

        pendingRuns.forEach(pendingRun => pendingRunRows.push([
            pendingRun.customerName,
            pendingRun.orderNumber,
            formatDateTime(pendingRun.dateReceived),
            <ViewDetails onDetailsClick={() => this.onDetailsClick(pendingRun.id)} />,
            <Promote onPromoteClick={() => this.onPromoteClick(pendingRun.id, "bulk pack", pendingRun.orderNumber, pendingRun.customerName)} />
        ]));

        return pendingRunRows;
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onSearch = async (e) => {
        e.preventDefault();
        const { filter } = this.state;
        if (filter.search.length < 4) filter.search = '';
        this.setState({ loading: true, filteredResults: true, filter: filter });
        await this.getPendingRuns();
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { search: "", courier: "", runType: "" },
            filteredResults: false
        }, this.getPendingRuns);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getPendingRuns);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getPendingRuns);
        }
    }

    onStart = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: 1, loading: true }, this.getPendingRuns);
        }
    }

    onEnd = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: pageCount, loading: true }, this.getPendingRuns);
        }
    }

    render() {
        const { loading, pendingRuns, couriers, pageIndex, pageCount, filter, filteredResults, showConfirmQueueJumpModal, showErrorQueueJumpModal, showSuccessQueueJumpModal, promotedRun } = this.state;
        const noPendingRuns = pendingRuns.length === 0;
        const hasFilterRuns = filteredResults && noPendingRuns;

        return (
            <div className="center-block mt-5">
                {loading && <LoadingBar />}
                <Tab title="Run Queue" subNavList={BulkRunSubNav} activeKey="Pack" />
                <RunFilter
                    couriers={couriers}
                    filter={filter}
                    onFilterChange={this.onFilterChange}
                    onSearch={this.onSearch}
                    onClear={this.onClear}
                    showFilterMessage={hasFilterRuns} />
                {noPendingRuns && !hasFilterRuns ?
                    <NoPendingRuns runType="Bulk Pack" /> :
                    <PagingTable
                        headers={BulkTableHeaders}
                        rows={this.getPendingRunRows()}
                        onNext={this.onNext}
                        onPrevious={this.onPrevious}
                        onStart={this.onStart}
                        onEnd={this.onEnd}
                        pageIndex={pageIndex}
                        pageCount={pageCount} />}
                <ConfirmQueueJumpModal {...promotedRun} show={showConfirmQueueJumpModal} handleClose={this.onShowHideConfirmModal} handleConfirmAction={this.promoteRun} />
                <SuccessQueueJumpModal {...promotedRun} show={showSuccessQueueJumpModal} handleClose={this.onShowHideSuccessModal} handleConfirmAction={this.onShowHideSuccessModal} />
                <ErrorQueueJumpModal show={showErrorQueueJumpModal} handleClose={this.onShowHideErrorModal} handleConfirmAction={this.onShowHideErrorModal} />
            </div>
        );
    }
}
export default BulkPack;