const OrderRunSubNav = [
    { text: "Pick", link: "/runs/order" },
    { text: "Pack", link: "/runs/order/pack" }
];

const BulkRunSubNav = [
    { text: "Shelf Pick", link: "/runs/bulk" },
    { text: "Pallet Pick", link: "/runs/bulk/pallet" },
    { text: "Pack", link: "/runs/bulk/pack" }
];

const ReplenishmentRunSubNav = [
    { text: "Replenishment", link: "/runs/replenishment" },
    { text: "PFP Replenishment", link: "/runs/replenishment/pfp" }
];

const OrderTableHeaders = ["Retailer Name", "Order Number", "Date Received", "", ""];

const BulkTableHeaders = ["Retailer Name", "Order Number", "Date Received", "", ""];

const ReplenishmentTableHeaders = ["Retailer Name", "Date Received", "", ""];

export {
    OrderRunSubNav,
    BulkRunSubNav,
    ReplenishmentRunSubNav,
    OrderTableHeaders,
    BulkTableHeaders,
    ReplenishmentTableHeaders
}