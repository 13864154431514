import React from 'react';
import { NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faDollyFlatbed, faShoppingCart, faBox, faBoxes } from '@fortawesome/free-solid-svg-icons';
import { checkPermission } from './../Utilities';

import './NavMenu.scss';

const SideNavItem = ({ link, icon, title, exactLink = false, isDisabled = false }) =>
    <LinkContainer to={link} exact={exactLink}>
        {isDisabled
            ? <NavItem className="nav-disabled" onClick={(e) => e.preventDefault()}><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
            : <NavItem><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
        }
    </LinkContainer>

const NavMenu = () =>
    <div className="sidebar-wrapper pt-5">
        <div className="mt-3">
            {checkPermission('ViewWMSDashboard') ? <SideNavItem link="/" title="Dashboard" icon={faChartBar} exactLink={true} /> : null}
            {checkPermission('ViewWMSRuns') ? <SideNavItem link="/runs/" title="Run Queue" icon={faDollyFlatbed} isDisabled={true} /> : null}
            <div className="ml-4 nav-sub">
                {checkPermission('ViewWMSRuns') ? <SideNavItem link="/runs/order" title="Orders" icon={faShoppingCart} /> : null}
                {checkPermission('ViewWMSRuns') ? <SideNavItem link="/runs/bulk" title="Bulk Orders" icon={faBox} /> : null}
                {checkPermission('ViewWMSRuns') ? <SideNavItem link="/runs/replenishment" title="Replenishment" icon={faBoxes} /> : null}
            </div>
        </div>
    </div>

export default NavMenu;
