import React from 'react';
import { Row, Col, Button, InputGroup, FormControl, Badge, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { WarningAlert } from '../common/Alerts'
import { formatDateTime } from '../../Utilities';

import './Snippets.scss';

const RunTypeLabel = ({ runType }) =>
    <Badge className={GetRunTypeClass(runType)}>{runType}</Badge>

const NoPendingRuns = ({ runType }) =>
    <div className="message-empty">
        <div className="message">
            <p>There are no {runType} runs.</p>
        </div>
    </div>

const GetRunTypeClass = runType =>
    runType === "Pick" ? "pick" :
        runType === "Pack" ? "pack" :
            runType === "Bulk Shelf Pick" ? "bulk_shelf" :
                runType === "Bulk Pallet Pick" ? "bulk_pallet" :
                    runType === "Bulk Pack" ? "bulk_pack" :
                        runType === "Replenishment" ? "replen" :
                            runType === "PFP Replenishment" ? "pfp_replen" :
                                "";

const ViewDetails = ({ onDetailsClick }) =>
    <div className="text-center">
        <Button variant="outline-primary" className="m-0" onClick={onDetailsClick}>View Details</Button>
    </div>

const Promote = ({ onPromoteClick }) =>
    <div className="text-center">
        <Button variant="outline-primary" className="m-0" onClick={onPromoteClick}><FontAwesomeIcon icon={faArrowUp} /></Button>
    </div>

const RunFilter = ({ couriers, filter, onFilterChange, onSearch, onClear, showFilterMessage, isReplenRun = false }) =>
    <React.Fragment>
        <Row className="form-group-filter mt-3">
            <Col sm={12} md={4}>
                <InputGroup>
                    <FormControl type="text" name="search" placeholder={isReplenRun ? "Search by retailer name..." : "Search by order number or retailer name..."} value={filter.search} onChange={onFilterChange} min="4" />
                    <InputGroup.Append>
                        <InputGroup.Text id="searchBox" className="input-filter-icon">
                            <FontAwesomeIcon icon={faSearch} size="xs" />
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
            {couriers != null ? <Col sm={12} md={3}>
                <FormControl as="select" name="courier" value={filter.courier} onChange={onFilterChange}>
                    <option key="" value="" selected >Filter by Courier...</option>;
                        {couriers.map(c => (<option key={c} value={c}>{c}</option>))}
                </FormControl>
            </Col> : null}
        </Row>
        <div className="button-group-filter mb-3">
            <Button variant="primary" className="float-right ml-3" onClick={onSearch}>Apply Filters</Button>
            <Button variant="link" className="float-right link-button filter-clear-link" onClick={onClear}>Clear Filters</Button>
        </div>
        {showFilterMessage &&
            <Row>
                <Col md={6}>
                    <WarningAlert message="There are no results matching your search, check that you entered the details correctly and try again." />
                </Col>
            </Row>
        }
    </React.Fragment>

const OrderDetails = ({ customerName, orderNumber, orderDate, courier }) =>
    <section className="section-first">
        <h2>Order Details</h2>
        <p className="section-title">Customer Name</p>
        <p>{customerName}</p>
        <p className="section-title">Order Number</p>
        <p>{orderNumber}</p>
        <p className="section-title">Order Date</p>
        <p>{formatDateTime(orderDate)}</p>
        <p className="section-title">Courier</p>
        <p>{courier}</p>
    </section>

const RunDetails = ({ runType, runDate, transitionBox = null, transitionArea = null }) =>
    <section>
        <h2>Run Details</h2>
        <p className="section-title">Run Type</p>
        <RunTypeLabel runType={runType} />
        <p className="section-title">Run Created</p>
        <p>{runDate ? formatDateTime(runDate) : "This run has not been created yet"}</p>
        {transitionBox && <React.Fragment>
            <p className="section-title">Transistion Box</p>
            <p>{transitionBox}</p>
        </React.Fragment>}
        {transitionArea && <React.Fragment>
            <p className="section-title">Transistion Area</p>
            <p>{transitionArea}</p>
        </React.Fragment>}
    </section>

const ProductDetails = ({ customerName, productName, palletLocation }) =>
    <section>
        <h2>Products Details</h2>
        <p className="section-title">Customer Name</p>
        <p>{customerName}</p>
        <p className="section-title">Product Name</p>
        <p>{productName}</p>
        <p className="section-title">Pallet Location</p>
        <p>{palletLocation}</p>
    </section>

const RunDetailsError = () =>
    <Row>
        <Col md={6}>
            <WarningAlert message="We are currently unable to retrieve details for this run." />
        </Col>
    </Row>

const ConfirmQueueJumpModal = ({ runName, orderNumber, customerName, show, handleClose, handleConfirmAction, isReplenRun = false }) =>
    <Modal className="modal-queue" show={show} onHide={handleClose} centered>
        <Modal.Header className="no-border" closeButton>
            <Modal.Title>Confirm Queue Jump</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Confirm that you want to move a <strong>{runName} run </strong>{!isReplenRun && "for order "}<strong>{orderNumber}</strong> belonging to <strong>{customerName}</strong> to the top of the pick queue position? This action will be logged and cannot be undone.</p>
        </Modal.Body>
        <Modal.Footer className="no-border text-center">
            <Row>
                <Col xs={{ span: 12, order: '2' }} sm={{ span: 12, order: '2' }} md={{ span: 7, order: '1' }} lg={{ span: 7, order: '1' }} className="col-link">
                    <Button variant="link" onClick={handleClose} className="cancel-link underline link-button">Cancel</Button>
                </Col>
                <Col xs={{ span: 12, order: '1' }} sm={{ span: 12, order: '1' }} md={{ span: 5, order: '2' }} lg={{ span: 5, order: '2' }}>
                    <Button variant="primary" block onClick={handleConfirmAction}>Confirm and Move</Button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>

const SuccessQueueJumpModal = ({ runName, orderNumber, customerName, show, handleClose, handleConfirmAction, isReplenRun = false }) =>
    <Modal className="modal-queue" show={show} onHide={handleClose} centered>
        <Modal.Header className="no-border" closeButton>
            <Modal.Title>Confirm Queue Jump</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-block text-center mx-3 mb-3">
                <img src="Images/delivery.svg" alt="Queue jump successful" />
            </div>
            <p className="modal-subtitle text-center">Success!</p>
            <p className="text-center">The {runName} run {!isReplenRun && <strong> for order {orderNumber}</strong>} belonging to <strong>{customerName}</strong> has been placed at the top of the queue.</p>
        </Modal.Body>
        <Modal.Footer className="no-border text-center">
            <div className="text-center">
                <Button variant="primary" className="px-5" onClick={handleConfirmAction}>Ok</Button>
            </div>
        </Modal.Footer>
    </Modal>

const ErrorQueueJumpModal = ({ show, handleClose, handleConfirmAction }) =>
    <Modal className="modal-queue" show={show} onHide={handleClose} centered>
        <Modal.Header className="no-border" closeButton>
            <Modal.Title>Queue Jump Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-block text-center mx-3 mb-3">
                <img src="Images/help.svg" alt="Queue jump successful" />
            </div>
            <p className="modal-subtitle text-center">Oh no!</p>
            <p className="text-center">This run cannot be jumped up the queue as it has been picked up already.</p>
        </Modal.Body>
        <Modal.Footer className="no-border text-center">
            <div className="text-center">
                <Button variant="primary" className="px-5" onClick={handleConfirmAction}>Ok</Button>
            </div>
        </Modal.Footer>
    </Modal>

export {
    RunTypeLabel,
    GetRunTypeClass,
    NoPendingRuns,
    ViewDetails,
    Promote,
    RunFilter,
    OrderDetails,
    RunDetails,
    ProductDetails,
    RunDetailsError,
    ConfirmQueueJumpModal,
    SuccessQueueJumpModal,
    ErrorQueueJumpModal
}