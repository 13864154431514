const USER_API = process.env.REACT_APP_USER_API;
const FULFILMENT_API = process.env.REACT_APP_FULFILMENT_API;

const CommonEndpoints = {

    USER: {
        POST: {
            LOGIN: USER_API + '/api/user/wmslogin',
        }
    },
    PASSWORD: {
        GET: {
            BY_ID: USER_API + '/api/passwordresetrequest/getliverequestbyid/'
        },
        PUT: {
            CHANGE_PASSWORD: USER_API + '/api/passwordresetrequest/changepassword',
            RESET_PASSWORD: USER_API + '/api/passwordresetrequest/resetpassword/'
        }
    },
    WAREHOUSE:{
        GET: {
            COUNTS: FULFILMENT_API + '/api/warehousemanagement/run/counts',
            COURIERS: FULFILMENT_API + '/api/warehousemanagement/couriers'
        }
    },
    RUNS:{
        GET: {
            ORDER_PICK: FULFILMENT_API + '/api/warehousemanagement/run/pick',
            ORDER_PACK: FULFILMENT_API + '/api/warehousemanagement/run/pack',
            BULK_SHELF: FULFILMENT_API + '/api/warehousemanagement/run/bulkshelfpick',
            BULK_PALLET: FULFILMENT_API + '/api/warehousemanagement/run/bulkpalletpick',
            BULK_PACK: FULFILMENT_API + '/api/warehousemanagement/run/bulkpack',
            REPLEN_STOCK: FULFILMENT_API + '/api/warehousemanagement/run/stockreplenishment',
            REPLEN_PFP: FULFILMENT_API + '/api/warehousemanagement/run/pfpreplenishment',

            ORDER_PICK_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/pick/',
            ORDER_PACK_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/pack/',
            BULK_SHELF_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/bulkshelfpick/',
            BULK_PALLET_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/bulkpalletpick/',
            BULK_PACK_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/bulkpack/',
            REPLEN_STOCK_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/stockreplenishment/',
            REPLEN_PFP_DETAILS: FULFILMENT_API + '/api/warehousemanagement/run/pfpreplenishment/'
        },
        PUT : {
            ORDER_PICK_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/pick/{id}/bump',
            ORDER_PACK_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/pack/{id}/bump',
            BULK_SHELF_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/bulkshelfpick/{id}/bump',
            BULK_PALLET_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/bulkpalletpick/{id}/bump',
            BULK_PACK_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/bulkpack/{id}/bump',
            REPLEN_STOCK_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/stockreplenishment/{id}/bump',
            REPLEN_PFP_BUMP: FULFILMENT_API + '/api/warehousemanagement/run/pfpreplenishment/{id}/bump'
        }
    }

}

export default CommonEndpoints;