import { tokenisedFetch } from './Utilities';

const POST = (url, data) => {
    return tokenisedFetch(url, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    });
};

const POST_WITH_HEADER = (url, header, data) => {
    console.log(header)
    return fetch(url, {
        method: 'POST',
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + header},
        body: JSON.stringify(data)
    });
};

const PUT = (url, data) => {
    return tokenisedFetch(url, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    });
};

const GET = (url) => {
    return tokenisedFetch(url);
};

const extractData = (returnObject) => {
    if (returnObject) {
        if (!returnObject.error) {
            return returnObject.data;
        }
    }
    return null;
};

export {
    POST,
    POST_WITH_HEADER,
    PUT,
    GET,
    extractData
};