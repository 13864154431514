import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { ProtectedRoute } from './Utilities';

import Layout from './layout/Layout';

import Login from './components/login/Login';

import Forgot from './components/account/password/Forgot';
import Sent from './components/account/password/Sent';
import Expired from './components/account/password/Expired';
import Reset from './components/account/password/Reset';
import Complete from './components/account/password/Complete';

import Dashboard from './components/dashboard/Dashboard';

import OrderPick from './components/runs/order/OrderPick';
import OrderPack from './components/runs/order/OrderPack';
import OrderPickDetails from './components/runs/order/OrderPickDetails';
import OrderPackDetails from './components/runs/order/OrderPackDetails';
import BulkShelf from './components/runs/bulk/BulkShelf';
import BulkPallet from './components/runs/bulk/BulkPallet';
import BulkPack from './components/runs/bulk/BulkPack';
import BulkShelfDetails from './components/runs/bulk/BulkShelfDetails';
import BulkPalletDetails from './components/runs/bulk/BulkPalletDetails';
import BulkPackDetails from './components/runs/bulk/BulkPackDetails';
import StockReplenishment from './components/runs/replenishment/StockReplenishment';
import PFPReplenishment from './components/runs/replenishment/PFPReplenishment';
import StockReplenishmentDetails from './components/runs/replenishment/StockReplenishmentDetails';
import PFPReplenishmentDetails from './components/runs/replenishment/PFPReplenishmentDetails';

import './App.scss';

class App extends Component {

  state = {
    loggedIn: false
  };

  isLoggedIn = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? true : false;
  }

  onLogin = (data) => {
    if (data.token) {
      localStorage.setItem('user', JSON.stringify(data));
      this.setState({ loggedIn: true });
    }
  }

  onLogout = () => {
    this.setState({ loggedIn: false });
    localStorage.clear();
    this.props.history.push('/');
  }

  renderHome = () => (
    <Layout onLogout={this.onLogout}>
      <Switch>
        <ProtectedRoute feature="ViewWMSDashboard" path='/' component={Dashboard} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/order' component={OrderPick} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/order/pack' component={OrderPack} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/order/pick/:id' component={OrderPickDetails} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/order/pack/:id' component={OrderPackDetails} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/bulk' component={BulkShelf} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/bulk/pallet' component={BulkPallet} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/bulk/pack' component={BulkPack} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/bulk/shelf/:id' component={BulkShelfDetails} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/bulk/pallet/:id' component={BulkPalletDetails} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/bulk/pack/:id' component={BulkPackDetails} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/replenishment' component={StockReplenishment} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/replenishment/pfp' component={PFPReplenishment} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/replenishment/stock/:id' component={StockReplenishmentDetails} exact />
        <ProtectedRoute feature="ViewWMSRuns" path='/runs/replenishment/pfp/:id' component={PFPReplenishmentDetails} exact />
      </Switch>
    </Layout>
  )

  renderLogin = () => (
    <React.Fragment>
      <Switch>
        <Route exact path='/' render={() => (<Login onLogin={this.onLogin} />)} />
        <Route path='/account/password/forgot' component={Forgot} exact />
        <Route path='/account/password/sent' component={Sent} exact />
        <Route path='/account/password/expired' component={Expired} exact />
        <Route path='/account/password/reset/:passwordresetid' component={Reset} exact />
        <Route path='/account/password/complete' component={Complete} exact />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </React.Fragment>
  )

  render() {
    
    return (
        this.isLoggedIn() ? this.renderHome() : this.renderLogin()
    );
  };

}

export default withRouter(App);
