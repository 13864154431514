import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

const DashboardTile = ({ icon, iconClass, title, count }) =>
    <Col xs={6} sm={6} md={4} lg={4} className="mt-3">
        <Card className="card-body px-1 py-3 d-flex flex-row dashboard-tile">
            <div className="fa-4x icon">
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faSquare} className={`${iconClass}_bg`} />
                    <FontAwesomeIcon icon={icon} inverse transform="shrink-8" className={`${iconClass}`} />
                </span>
            </div>
            <div className="media-body">
                <Card.Subtitle className="run mb-2 mt-1">{title}</Card.Subtitle>
                <Card.Title className="total">{count}</Card.Title>
            </div>
        </Card>
    </Col>

export {
    DashboardTile
};