import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import Title from '../common/Title';
import { faDollyFlatbed, faTruck, faPeopleCarry, faArchive, faBoxes, faBoxOpen, faPallet } from '@fortawesome/free-solid-svg-icons';
import { DashboardTile } from '../dashboard/Snippets';
import { GET } from '../../Consumer';
import Endpoints from '../../components/common/Endpoints';
import LoadingBar from '../../components/common/LoadingBar';

import '../dashboard/Dashboard.scss';

const getThresholdClass = (threshold) => threshold === "High" ? "card-threshold-high" : threshold === "Medium" ? "card-threshold-medium" : threshold === "Low" ? "card-threshold-low" : "";

class Dashboard extends Component {

    intervalID;

    state = {
        loading: true,
        dashboardDetails: {},
    }

    async componentDidMount() {
        await this.getData();
    }

    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }

    getData = async () => {
        const response = await GET(Endpoints.WAREHOUSE.GET.COUNTS);
        const result = await response.json();
        this.setState({ dashboardDetails: result ? result.data : {}, loading: false });
        this.intervalID = setTimeout(this.getData.bind(this), 60000);
    }

    render() {
        const { loading, dashboardDetails } = this.state
        return (
            <React.Fragment>
                {loading ? <LoadingBar />  :
                    <div className="center-block mt-5">
                        <Title title="Dashboard" />
                        <Row>
                            <DashboardTile icon={faDollyFlatbed} title="Pick" count={dashboardDetails.pickerRun.count} iconClass={getThresholdClass(dashboardDetails.pickerRun.state)} />
                            <DashboardTile icon={faTruck} title="Pack" count={dashboardDetails.packerRun.count} iconClass={getThresholdClass(dashboardDetails.packerRun.state)} />
                            <DashboardTile icon={faPeopleCarry} title="Replenishment" count={dashboardDetails.stockReplenishmentRun.count} iconClass={getThresholdClass(dashboardDetails.stockReplenishmentRun.state)} />
                            <DashboardTile icon={faArchive} title="Bulk Shelf Pick" count={dashboardDetails.bulkShelfPickRun.count} iconClass={getThresholdClass(dashboardDetails.bulkShelfPickRun.state)} />
                            <DashboardTile icon={faBoxes} title="Bulk Pallet Pick" count={dashboardDetails.bulkPalletPickRun.count} iconClass={getThresholdClass(dashboardDetails.bulkPalletPickRun.state)} />
                            <DashboardTile icon={faBoxOpen} title="Bulk Pack" count={dashboardDetails.bulkPackRun.count} iconClass={getThresholdClass(dashboardDetails.bulkPackRun.state)} />
                            <DashboardTile icon={faPallet} title="PFP Replenishment" count={dashboardDetails.pfpStockReplenishmentRun.count} iconClass={getThresholdClass(dashboardDetails.pfpStockReplenishmentRun.state)} />
                        </Row>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default Dashboard;