import React, { Component } from 'react';
import LoadingBar from '../../common/LoadingBar';
import Breadcrumbs from '../../common/navigation/Breadcrumbs';
import { GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { OrderDetails, RunDetails, RunDetailsError } from '../Snippets';

const breadcrumbs = (orderNumber) => [
    { text: "Shelf Pick", link: "/runs/bulk" },
    { text: `Order ${orderNumber}`, link: null }
];

class BulkShelfDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            runId: props.match.params.id || null,
            run: {}
        }
    }

    async componentDidMount() {
        await this.getRun();
        this.setState({ loading: false });
    }

    getRun = () => {
        const { runId } = this.state;
        return GET(Endpoints.RUNS.GET.BULK_SHELF_DETAILS + runId)
            .then(response => response.json())
            .then(result => {
                const run = result.data;
                const error = run ? false : true;
                this.setState({ run: run, error: error });
            });
    }

    render() {
        const { run, error, loading } = this.state;

        return (
            <div className="center-block mt-5">
                <h1 className="mb-3">Run Queue</h1>
                {loading ? <LoadingBar />
                    : error ? <RunDetailsError />
                        : <React.Fragment>
                            <Breadcrumbs breadcrumbs={breadcrumbs(run.orderNumber)} />
                            <OrderDetails customerName={run.customerName} orderNumber={run.orderNumber} orderDate={run.orderDate} courier={run.courier} />
                            <RunDetails runType={run.runType} runDate={run.runCreatedDate} transitionArea={run.transitionArea} />
                        </React.Fragment>
                }
            </div>
        )
    }
}

export default BulkShelfDetails
