import { Route } from 'react-router-dom';
import { format } from 'date-fns';

const getUser = () => JSON.parse(localStorage.getItem('user') || "null");

const tokenisedFetch = (url, init)=> {
    let reqInit = init || {};
    let headers = Object.assign({}, reqInit.headers, authHeader());

    return fetch(url, { ...reqInit, headers: headers, credentials: 'include' });
}

const authHeader = () => {
    const user = getUser();
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

const checkPermission = (feature) => {
    const user = getUser();
    return user && user.token && user.permissions.indexOf(feature) >= 0;
}

const ProtectedRoute = ({ feature, path, component }) => checkPermission(feature) ? <Route exact path={path} component={component} /> : null;

const formatDateTime = (dateTimeString) => format(new Date(dateTimeString), 'dd MMM yyyy') + ' at ' + format(new Date(dateTimeString), 'HH:mm');

const setFilterData = (name, data) => localStorage.setItem(name, JSON.stringify(data));

const getFilterData = (name) => JSON.parse(localStorage.getItem(name) || "null");

const removeFilterData = (name) => localStorage.removeItem(name);

export {
    authHeader,
    tokenisedFetch,
    checkPermission,
    ProtectedRoute,
    formatDateTime,
    setFilterData,
    getFilterData,
    removeFilterData
}