import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'react-bootstrap';
import { PUT } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';
import { ErrorAlert } from '../../common/Alerts';
import CardPage from '../../common/CardPage';
import Endpoints from '../../common/Endpoints';

class Sent extends Component {

    validator = new FormValidator([
        {
            field: 'email',
            method: 'isEmail',
            validWhen: true,
            message: 'Please enter your email address'
        }
    ]);

    state = {
        email: this.props.location.state !== undefined ? this.props.location.state.email : undefined,
        errorMessages: []
    }

    handleReturnLogin = (e) => {
        e.preventDefault();
        this.props.history.push('/');
    }

    handleResendRequest = (e) => {
        e.preventDefault();

        const { email } = this.state

        return PUT(Endpoints.PASSWORD.PUT.RESET_PASSWORD + email)
            .then(response => response.json())
            .then((result) => {
                if (result.error) result.data !== null ? this.setState({ errorMessages: result.data }) : this.setState({ errorMessages: result.message });
                else this.props.history.push({ pathname: "/" });
            }).catch(() => this.setState({ errorMessages: ["There has been an issue resending link."] }));
    }

    renderSentContent = () => {
        const { email, errorMessages } = this.state
        return (<React.Fragment>
            {errorMessages.length > 0 && <ErrorAlert messages={errorMessages} />}
            <h3>Reset Password</h3>
            <p>We have sent an email to {email} with a reset password link. Click the link in the email to reset your password.</p>
            <div className="d-block text-center m-5">
                <img src="Images/email.svg" alt="" height="150" />
            </div>
            <p>Check your junk folder or resend the password reset email. If you need further help, contact <a href="mailto:support@selazar.com">support@selazar.com</a></p>
            <Button variant="outline-primary" block type="submit" value="Submit" className="mb-4" onClick={this.handleResendRequest}>Resend password reset email</Button>
            <p className="font-weight-bold mb-0">Have your reset your password?</p>
            <Button variant="primary" block type="submit" value="Submit" onClick={this.handleReturnLogin}>Login</Button>
        </React.Fragment>);
    }

    render() {
        return (
            <CardPage content={this.renderSentContent()} />
        );
    }
}

export default withRouter(Sent);