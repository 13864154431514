import React from 'react';
import { Card } from 'react-bootstrap';

const WarningAlert = ({ message }) => (
    <Card className="card-warning mb-3">
        <Card.Body>
            <p>{message}</p>
        </Card.Body>
    </Card>
);

const ErrorAlert = ({ messages }) => (
    <Card className="card-danger mb-3">
        <Card.Body>
            {typeof messages === 'string'
                ? <p>{messages}</p>
                : messages.map((e, i) => <p key={i}>{e}</p>)
            }
        </Card.Body>
    </Card>
);

export {
    WarningAlert,
    ErrorAlert
}