import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from './Header';
import NavMenu from './NavMenu';

import './Layout.scss';

const Layout = ({ onLogout, children }) =>
    <React.Fragment>
        <Header onLogout={onLogout} />
        <div className="content-wrapper">
            <NavMenu />
            <Container fluid>
                <Row className="p-4">
                    <Col>
                        {children}
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>

export default Layout;

